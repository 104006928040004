<template>
  <div>
    <v-fade-transition mode="out-in">
      <div class="assign-tag-overlay" v-if="state.assignReader.open">
        <v-container justify-center fill-height @click.self="$store.commit('state/setAssignReaderState', null)">
          <v-card width="400" class="pa-3 text-xs-left">
            <div class="title grey--text text--darken-1">{{$t('reader.assigning_identifier')}}</div>
            <v-tabs
              v-model="active"
              color="transparent"
              dark
              class="hidden-tabs-container"
            >
              <v-tab
                v-for="(guest, i) in state.assignReader.guests"
                :key="i"
                class="display-none"
              />
              <v-tab-item
                v-for="(guest, i) in state.assignReader.guests"
                :key="i"
              >
                <div class="title grey--text text--darken-1 mt-2" v-if="state.assignReader.guests.length > 1">Guest {{i + 1}} of {{state.assignReader.guests.length}}</div>
                <div class="display-1 grey--text text--darken-1 mt-1 mb-3 text-center">{{ getGuestFullName(guest) }}</div>
                <v-layout justify-center align-center>
                  <v-flex xs12 :sm9="!state.reader.error">
                    <v-layout justify-center align-center>
                      <v-icon v-if="state.reader.error" class="reader-icon mr-2" color="error">mdi-alert-circle-outline</v-icon>
                      <v-icon v-else-if="connecting" class="reader-icon mr-2">mdi-clock-outline</v-icon>
                      <v-icon v-else class="reader-icon mr-2">mdi-nfc-variant</v-icon>
                      <div v-if="state.reader.error" class="flex-column" style="height: 100%;">
                        <div class="title grey--text text--darken-1">{{$t(errorText.title)}}</div>
                        <div class="subheading grey--text text--darken-1" v-if="$te(errorText.description)">{{$t(errorText.description)}}</div>
                        <v-spacer/>
                        <v-btn @click="retry()" small class="ma-0 px-0 flex-none" text color="primary">{{ $t('actions.retry') }}</v-btn>
                      </div>
                      <v-layout v-else column class="grey--text text--darken-1">
                        <span v-if="state.assignReader.open && !connecting" class="title">{{ $t('reader.bring_wristband_closer') }}</span>
                        <span v-if="state.assignReader.open && connecting" class="title">{{$t('reader.attempting_connection')}}</span>
                      </v-layout>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-tab-item>
            </v-tabs>
            <div class="text-xs-center mt-3">
              <v-btn v-if="state.assignReader.guests.length > 1 && active < state.assignReader.guests.length -1" text @click="active = active + 1">{{$t('actions.skip')}}</v-btn>
              <v-btn v-if="state.assignReader.guests.length > 1 && active === state.assignReader.guests.length -1" text @click="$store.commit('state/setAssignReaderState', null)">{{$t('actions.close')}}</v-btn>
            </div>
          </v-card>
        </v-container>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import peripheralConnectionMixins from '@/mixins/PeripheralSocket'
import { getGuestFullName } from '@/utils/GuestUtils'

export default {
  props: {
    bookingHasTagsAssigned: Boolean
  },
  data () {
    return {
      active: null,
      copy: false,
      accessToAllBookedRooms: true,
      getGuestFullName
    }
  },
  mixins: [
    peripheralConnectionMixins
  ],
  created () {
    this.copy = this.bookingHasTagsAssigned
  },
  methods: {
    next (data) {
      if (data.success) {
        this.$emit('update')
        this.$store.commit('state/setMessage', { text: this.$t('reader.success.key_generated'), color: 'success' })
        this.active = false
        this.$store.commit('state/setAssignReaderState', null)
      } else {
        this.$store.commit('state/setReaderError', data.error_code)
      }
    },
    hasTag (guest) {
      return guest.externalIds.some((external) => this.services.list.find(s => s.id === external.serviceId).type === 'CORE')
    },
    assignTag (guestId) {
      this.cancelAction()
      this.$store.commit('state/setReaderError', null)
      this.sendAction('ENCODE_GUEST_KEY', { guest_id: guestId, copy: this.copy, access_to_all_booked_rooms: this.accessToAllBookedRooms }, this.next, (error) => {
        this.$store.commit('state/setReaderError', error.code)
      })
    }
  },
  computed: {
    ...mapState(['state', 'services']),
    errorText () {
      if (!this.state.reader.error) {
        return {}
      } else if (this.$te(`reader.error.${this.state.reader.error}.title`)) {
        return {
          title: `reader.error.${this.state.reader.error}.title`,
          description: `reader.error.${this.state.reader.error}.description`
        }
      } else {
        return {
          title: `reader.error.FALLBACK.title`,
          description: `reader.error.FALLBACK.description`
        }
      }
    },
    connecting () {
      return !this.state.reader.error && !this.state.reader.connected && this.state.reader.showConnecting
    }
  },
  beforeDestroy () {
    this.cancelAction()
  },
  watch: {
    'active' (val) {
      this.assignTag(this.state.assignReader.guests[val].id)
    },
    bookingHasTagsAssigned () {
      this.copy = this.bookingHasTagsAssigned
    }
  }
}
</script>

<style lang="scss">
.assign-tag-overlay {
  background-color: rgba(0, 0, 0, 0.30);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
}
.reader-icon {
  font-size: 70px !important;
  transition: all 0.2s ease-in-out;
}
.hidden-tabs-container {
  .v-tabs-bar {
    display: none;
  }
}
</style>
