import { render, staticRenderFns } from "./AssignTagOverlay.vue?vue&type=template&id=2f0a7352&"
import script from "./AssignTagOverlay.vue?vue&type=script&lang=js&"
export * from "./AssignTagOverlay.vue?vue&type=script&lang=js&"
import style0 from "./AssignTagOverlay.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VCard,VContainer,VFadeTransition,VFlex,VIcon,VLayout,VSpacer,VTab,VTabItem,VTabs})
